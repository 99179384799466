<template>
    <div class="full-height-layout fill add-product-mapping-template">
        <mercur-card class="full-height-layout fill form mx-4 mb-3" v-if="answer">
            <h2 class="font-weight-normal">Add product to import</h2>
            <div class="row">
                <div class="col col-3">
                    <mercur-input v-model="form.productName" required data-test="supplierImportProductNameInput">
                        Product name
                    </mercur-input>
                </div>
                <div class="col col-3" v-if="categories && categories.length">
                    <mercur-select
                        v-model="form.categoryId"
                        :disabled="loading"
                        required
                        data-test="supplierImportProductCategorySelect"
                    >
                        <template v-slot:label>
                            Product Category
                        </template>
                        <option
                            v-for="category in categories"
                            :key="category.productCategoryId"
                            :value="category.productCategoryId"
                        >
                            {{ category.categoryName }}
                        </option>
                    </mercur-select>
                </div>
            </div>
            <div class="fill">
                <div class="fhf" v-if="answer === 'new'">
                    <p class="add-product-mapping-template__message">Optionally, you can select an existing product or
                        pick attributes and options manually</p>
                    <mercur-tabs class="fill">
                        <mercur-tab
                            id="tabProducts"
                            title="List of product templates"
                            class="fill"
                            v-if="isSupplier || supplierId !== user.supplierId">
                            <grid-header
                                :quickSearch.sync="templates.search"
                                data-test="supplierImportProductTemplateTableHeader"
                                class="mt-3"
                            ></grid-header>
                            <data-table
                                class="fill full-height-layout border"
                                :options="productsOptions"
                                :url="url"
                                :quickSearch="templates.search"
                                ref="grid"
                                data-test="supplierImportProductTemplateTableData"
                            ></data-table>
                        </mercur-tab>
                        <mercur-tab id="tabAttributes" title="Configure by attributes and options" class="fill">
                            <div class="row mt-3">
                                <div class="col col-4">
                                    <grid-header :quickSearch.sync="attributeFilter.search"></grid-header>
                                    <data-table
                                        class="fill full-height-layout border grid-attribute border"
                                        :options="attributesOptions"
                                        :items="attributesList"
                                        :quickSearch="attributeFilter.search"
                                        :selected-rows.sync="selectedAttributes"
                                        ref="attributesTable"
                                    ></data-table>
                                </div>
                                <div class="col col-4">
                                    <template v-if="optionsUrl">
                                        <div class="flex">
                                            <grid-header
                                                class="flex-1"
                                                :quickSearch.sync="optionsFilter.search"
                                            ></grid-header>
                                            <mercur-button
                                                class="btn btn-yellow text-uppercase add-product-mapping-template__button"
                                                @click="addConfiguration">
                                                <i class="fas fa-plus"></i>
                                                Add
                                            </mercur-button>
                                        </div>
                                        <data-table
                                            class="fill full-height-layout border"
                                            :options="optionsOptions"
                                            :items="optionsList"
                                            :quickSearch="optionsFilter.search"
                                            :selected-rows.sync="selectedOptions"
                                            ref="optionsGrid"
                                        ></data-table>
                                    </template>
                                </div>
                                <div class="col col-4">
                                    <h3 class="add-product-mapping-template__combinations">
                                        List of added combinations
                                    </h3>
                                    <ul class="add-product-mapping-template__list">
                                        <li v-for="(values, key) in configuration" :key="key">
                                            <strong>{{ key }}
                                                <mercur-button
                                                    class="btn btn-icon text-uppercase vertical-middle"
                                                    @click="removeKey(key)">
                                                    <i class="fas fa-trash"></i>
                                                </mercur-button>
                                            </strong>
                                            <add-attribute-option-component
                                                class="inline-add"
                                                :passed-method="addCustomOption"
                                                :parent-key="key"></add-attribute-option-component>
                                            <p>
                                                {{
                                                    Object.values(values).map(value => `${value.option}${value.postfix ? value.postfix : ''}`).join(', ')
                                                }}</p>
                                        </li>
                                    </ul>
                                    <mercur-button
                                        class="btn btn-yellow text-uppercase"
                                        @click="getAttributesByProductFamily"
                                        :disabled="loading"
                                    >
                                        Use recommended values by product name
                                    </mercur-button>
                                </div>
                            </div>
                        </mercur-tab>
                    </mercur-tabs>
                </div>
                <div v-if="answer === 'own'">
                    <p class="add-product-mapping-template__message">Optionally, you can select one of your existing
                        products.</p>
                    <grid-header :quickSearch.sync="templates.search"></grid-header>
                    <data-table
                        class="fill full-height-layout border"
                        :options="productsOptions"
                        :url="url"
                        ref="grid"
                        :quickSearch="templates.search"
                    ></data-table>
                </div>
            </div>

            <div class="align-right">
                <mercur-button
                    class="btn btn-yellow"
                    type="submit"
                    :disabled="buttonsDisabled || $v.form.$invalid"
                    @click="save"
                    data-test="supplierImportProductProceed"
                >
                    Save and continue
                </mercur-button>
            </div>

        </mercur-card>
        <mercur-dialog
            v-if="supplierProductImportedDetails && supplierProductImportedDetails.productConfigurationTemplateId && this.configuration && Object.keys(configuration).length"
            :is-open.sync="isDialog">
            <div>
                <p>You selected existing product and added attribute/options configuration. <br> Which one do you want
                    to use for this template?</p>
                <div class="row">
                    <div class="col col-6">
                        <p>Existing product configuration</p>
                        <p>Selected product:
                            <strong
                                v-if="productTemplates.length && supplierProductImportedDetails.productConfigurationTemplateId">
                                {{
                                    productTemplates.find(template => template.productConfigurationId === supplierProductImportedDetails.productConfigurationTemplateId).productConfigurationName
                                }}
                            </strong>
                        </p>
                        <mercur-button class="btn btn-yellow text-uppercase" @click="addTemplate('config')">Select</mercur-button>
                    </div>
                    <div class="col col-6">
                        <p>Built configuration from attributes/options</p>
                        <ul class="add-product-mapping-template__list">
                            <li v-for="(values, key) in configuration" :key="key">
                                <strong>{{ key }}
                                    <mercur-button class="btn-icon vertical-middle" @click="removeKey(key)">
                                        <i class="fas fa-delete"></i>
                                    </mercur-button>
                                </strong>
                                <p><span v-for="value in values" :key="value.option">{{ value.option }}, </span></p>
                            </li>
                        </ul>
                        <mercur-button class="btn btn-yellow text-uppercase m-0" @click="addTemplate('product')">Select</mercur-button>
                    </div>
                </div>
            </div>
        </mercur-dialog>
        <mercur-dialog
            :is-open.sync="isYesNoDialog"
        >
            <p>Is this an existing or supplier own product?</p>
            <mercur-button data-test="newProduct" class="btn btn-yellow text-uppercase" @click="submitAnswer('new')">New Product</mercur-button>
            <mercur-button class="btn btn-yellow text-uppercase" @click="submitAnswer('own')">Existing Product</mercur-button>
        </mercur-dialog>
    </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import { mapState } from 'vuex'
import SupplierImportedProductMixin from './SupplierImportedProductMixin'
import AddAttributeComponent from '../../../components/products/AddAttributeComponent'
import AddAttributeOptionComponent from '../../../components/products/AddAttributeOptionComponent'
import { getDeepProperty } from '@/components/utils/Utils'

export default {
    name: 'AddEditProductMappingTemplate',
    mixins: [SupplierImportedProductMixin],
    components: { DataTable, GridHeader, AddAttributeOptionComponent },
    data () {
        return {
            loading: false,
            isYesNoDialog: true,
            answer: null,
            selectedAttributes: [],
            attributesList: null,
            attributeFilter: {
                search: '',
            },
            optionsFilter: {
                search: '',
            },
            templates: {
                search: '',
            },
            form: {},
            optionsUrl: null,
            optionsList: null,
            selectedOptions: [],
            configuration: {},
            isDialog: false,
            activeTab: 'tabProducts',
            facilities: null,
            productTemplates: [],
            productsOptions: {
                columns: {
                    ' ': {
                        maxWidth: 70,
                        valueGetter: ({ data }) => {
                            if (this.isNew) {
                                return data.productConfigurationId === this.form.productConfigurationTemplateId
                            }
                            const productConfigurationTemplateId = getDeepProperty('supplierProductImportedDetails.productConfigurationTemplateId', this, null)

                            if (productConfigurationTemplateId === null) {
                                return false
                            }

                            return data.productConfigurationId === productConfigurationTemplateId
                        },
                        cellRendererFramework: 'CheckboxCell',
                        cellRendererParams: (params) => {
                            return {
                                setValue: (value) => {
                                    if (!value) {
                                        if (this.isNew) {
                                            this.form.productConfigurationTemplateId = null
                                            return
                                        }
                                        this.$set(this.supplierProductImportedDetails, 'productConfigurationTemplateId', null)

                                        if (this.supplierProductImportedDetails && this.supplierProductImportedDetails.attributeConfiguration && Object.keys(this.supplierProductImportedDetails.attributeConfiguration).length) {
                                            this.$set(this, 'configuration', JSON.parse(JSON.stringify(this.supplierProductImportedDetails.attributeConfiguration)))
                                            this.$set(this, 'activeTab', 'tabAttributes')
                                        }
                                        return
                                    }

                                    if (this.isNew) {
                                        this.$set(this.form, 'productConfigurationTemplateId', params.data.productConfigurationId)
                                        return
                                    }
                                    this.$set(this.supplierProductImportedDetails, 'productConfigurationTemplateId', params.data.productConfigurationId)
                                },
                            }
                        },
                    },
                    'Name': {
                        field: 'productConfigurationName',
                        sortable: true,
                        link: this.isAllowedTo('SupplierCentral/getProductConfigurationById') ? (value, data) => {
                            try {
                                return {
                                    name: 'SupplierProductView',
                                    params: {
                                        supplierId: this.supplierId,
                                        productConfigurationId: data.productConfigurationId,
                                    },
                                }
                            } catch (e) {
                            }
                        } : null,
                    },
                    'Published': {
                        field: 'productConfigurationStatus',
                        statusChip: true,
                    },
                    'Attributes': {
                        field: 'attributeConfiguration',
                        cellClass: 'ag-grid__cell--multi-line',
                        autoHeight: true,
                        valueFormatter: ({ value }) => {
                            try {
                                if (!value || typeof value !== 'object' || Array.isArray(value)) {
                                    return ''
                                }
                                return Object.keys(value.selectedAttributes).join(', ')
                            } catch (e) {
                                return ''
                            }
                        },
                        width: 600,
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                },
                rowSelection: 'single',
                quickSearchColumns: ['productConfigurationName'],
                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
                cacheBlockSize: 500,
                onDataReceived: items => {
                    this.$set(this, 'productTemplates', items)
                },
            },
            attributesOptions: {
                columns: {
                    'Attribute Name': {
                        field: 'attributeName',
                        checkboxSelection: true,
                    },
                },
                rowSelection: 'single',
                pagination: false,
                quickSearchColumns: ['attributeName'],
                menuTabs: [],
                frameworkComponents: {
                    AddAttributeComponent,
                },
                statusBar: {
                    statusPanels: [
                        {
                            statusPanel: 'AddAttributeComponent',
                            align: 'left',
                            statusPanelParams: (params) => {
                                return {
                                    proposeAttributeAndOption: data => {
                                        this.setCustomAttributeAndOption(data)
                                    },
                                }
                            },
                        },
                    ],
                },
            },
            optionsOptions: {
                columns: {
                    'Option name': {
                        field: 'attributeOption',
                        checkboxSelection: true,
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                    },
                },
                quickSearchColumns: ['attributeOption'],
                pagination: false,
                menuTabs: [],
            },
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('productContent', ['categories']),
        buttonsDisabled () {
            return this.isLoading
        },
        url () {
            // PART OF WHEN VIRTUAL_SUPPLIER IS LOGGED IN TO CHANGE PRODUCTS FOR SUPPLIER
            let id = this.supplierId
            if (this.answer === 'new') {
                id = this.user.supplierId
            }

            if (this.user.supplierType === 'SUPPLIER' && this.answer === 'new') {
                id = this.user.parentId
            }

            return CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS.replace('{supplierId}', id)
        },
    },
    watch: {
        selectedAttributes: {
            handler (value) {
                if (!value || !value.length) {
                    return
                }
                this.$set(this, 'optionsList', null)
                this.$set(this, 'optionsUrl', CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST_OPTIONS.replace('{attributeName}', value[0].attributeName))
                this.$api.post(this.optionsUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                    this.$set(this, 'optionsList', data.data.items)
                    this.$nextTick(() => {
                        if (!this.configuration || !Object.keys(this.configuration).length) {
                            return
                        }
                        this.$refs.optionsGrid.gridApi.forEachNode(node => {
                            const alreadySelectedOptions = this.configuration[node.data.attributeName]
                            if (alreadySelectedOptions) {
                                const mapped = alreadySelectedOptions.flatMap(e => e.option)
                                node.setSelected(mapped.includes(node.data.attributeOption))
                            }
                        })
                    })
                })
            },
            immediate: true,
            deep: true,
        },
        supplierProductImportedDetails: {
            handler (value) {
                if (!value) {
                    return
                }

                const parsedValue = JSON.parse(JSON.stringify(value))
                this.initialValues = {
                    productName: parsedValue.productName,
                    categoryId: parsedValue.categoryId,
                    productConfigurationTemplateId: parsedValue.productConfigurationTemplateId,
                    attributeConfiguration: parsedValue.attributeConfiguration,
                }

                this.$set(this, 'form', {
                    productName: parsedValue.productName,
                    categoryId: parsedValue.categoryId,
                })

                if (!value.productConfigurationTemplateId && value.attributeConfiguration) {
                    this.$set(this, 'configuration', value.attributeConfiguration)
                }

                if (value.supplierOwnProduct) {
                    this.answer = 'own'
                } else {
                    this.answer = 'new'
                }
            },
            immediate: true,
        },
    },
    validations: {
        form: {
            productName: { required },
            categoryId: { required },
        },
    },
    methods: {
        save () {
            if (this.configuration === null || !this.configuration) {
                this.$set(this, 'configuration', {})
            }

            if ((!this.isNew || Object.keys(this.configuration).length) &&
                this.initialValues &&
                this.supplierProductImportedDetails &&
                this.supplierProductImportedDetails.productConfigurationTemplateId &&
                this.initialValues.productConfigurationTemplateId !== this.supplierProductImportedDetails.productConfigurationTemplateId
            ) {
                this.isDialog = true
                return
            }

            this.addTemplate()
        },
        addTemplate (ignore = '') {
            if (this.configuration === null || !this.configuration) {
                this.$set(this, 'configuration', {})
            }

            if (!this.form.productConfigurationTemplateId && !Object.keys(this.configuration).length && (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.productConfigurationTemplateId)) {
                this.$root.$emit('notification:global', {
                    message: 'Attribute configuration is required',
                    type: 'error',
                })
                return
            }

            const payload = JSON.parse(JSON.stringify(this.form))
            payload.categoryName = this.categories.find(e => e.productCategoryId === this.form.categoryId).categoryName
            if (Object.keys(this.configuration).length && ignore !== 'config') {
                payload.attributeConfiguration = this.configuration

                if (!this.isNew) {
                    payload.productConfigurationTemplateId = null
                }
            }

            if (this.supplierProductImportedDetails && this.supplierProductImportedDetails.productConfigurationTemplateId && ignore !== 'product') {
                payload.productConfigurationTemplateId = this.supplierProductImportedDetails.productConfigurationTemplateId
            }

            if (this.answer === 'own') {
                payload.supplierOwnProduct = true
            }

            if (!this.isNew) {
                this.$emit('save', payload, { pushRoute: { name: 'SupplierImportedProductUpload' } })
                return
            } else {
                if (!this.facilities) {
                    this.$root.$emit('notification:global', {
                        message: `Facilities are missing`,
                        type: 'error',
                    })
                    return
                }
                payload.facilities = this.facilities
            }

            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.CREATE.replace('{supplierId}', this.supplierId)

            this.isDialog = false

            this.$emit('execute', {
                fn: () => new Promise((resolve, reject) => {
                    this.$store.dispatch('tasks/addTask', {
                        title: `Download import product template csv`,
                        interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                        attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                        retryOnFail: false,
                        onComplete: data => {
                            this.skipCheckForChanges = true
                            if (data.url) {
                                this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.url)
                            }
                            resolve(data)
                        },
                        onPoll: () => {
                            return this.$api.post(url, payload).then(result => {
                                this.$root.$emit('notification:global', {
                                    message: `Product mapping template was added`,
                                })
                                return result
                            }).catch(reject)
                        },
                        handleResult: (result) => {
                            if (result.data.length === 0) {
                                return Promise.resolve(null)
                            }
                            return Promise.resolve(result.data)
                        },
                    })
                }),
                onSuccess: (data) => {
                    this.skipCheckForChanges = true
                    if (data.url) {
                        this.$emit('pushRoute', {
                            name: 'SupplierImportedProductUpload',
                            params: { supplierProductImportedId: data.data.supplierProductImportedId },
                            force: true,
                            refreshData: true })
                        return
                    }
                    this.$emit('goToHome', { supplierId: this.supplierId })
                },
            })
        },
        addConfiguration () {
            if (!this.selectedOptions.length) {
                if (this.optionsFilter.search === '') {
                    this.$root.$emit('notification:global', {
                        message: `Select at least one option or type custom name in search bar`,
                        type: 'error',
                    })
                    return
                }

                this.selectedOptions = [
                    {
                        attributeOption: this.optionsFilter.search,
                        attributeName: this.selectedAttributes[0].attributeName,
                        custom: true,
                    },
                ]
            }

            if (this.configuration === null) {
                this.$set(this, 'configuration', {})
            }

            const selectedOptions = JSON.parse(JSON.stringify(this.selectedOptions)).map((e) => {
                const mappedOption = {
                    option: e.attributeOption,
                    alternative: e.attributeOption,
                }

                if (e.custom) {
                    mappedOption.custom = true
                }
                return mappedOption
            })

            if (this.configuration[this.selectedAttributes[0].attributeName]) {
                const customOptions = JSON.parse(JSON.stringify(this.configuration[this.selectedAttributes[0].attributeName])).filter(e => e.custom)
                Array.prototype.push.apply(selectedOptions, customOptions)

                this.$set(this.configuration, this.selectedAttributes[0].attributeName, selectedOptions)
            } else {
                this.$set(this.configuration, this.selectedAttributes[0].attributeName, selectedOptions)
            }

            this.clearAttributeOptionData()
        },
        clearAttributeOptionData () {
            this.$set(this, 'selectedAttributes', [])
            this.$set(this, 'selectedOptions', [])
            this.$set(this, 'optionsUrl', null)
            this.$set(this, 'optionsList', null)
            this.$refs.attributesTable.deselectAllRows()
            this.$set(this.optionsFilter, 'search', '')
        },
        removeKey (key) {
            this.$delete(this.configuration, key)
        },
        submitAnswer (answer) {
            this.answer = answer
            this.isYesNoDialog = false
        },
        setCustomAttributeAndOption ({ attributeKey, attributeOption, postfix }) {
            this.$set(this.configuration, attributeKey, [{
                option: attributeOption.toLowerCase(),
                alternative: attributeOption.toLowerCase(),
                postfix,
                custom: true,
            }])
            this.$root.$emit('notification:global', {
                message: `Custom attribute and option was added`,
                type: 'success',
            })
        },
        addCustomOption (option, key, postfix = '') {
            const currentPostfix = this.configuration[key].find(option => option.postfix)
            this.configuration[key].push({
                option: option.toLowerCase(),
                alternative: option.toLowerCase(),
                custom: true,
                postfix: currentPostfix ? currentPostfix.postfix : '',
            })
            this.$root.$emit('notification:global', {
                message: `Custom option was added`,
                type: 'success',
            })
        },
        async getAttributesByProductFamily () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.GET_ATTRIBUTES_BY_PRODUCT_FAMILY.replace('{supplierId}', this.supplierId)
            if (!this.form.productName) {
                this.$root.$emit('notification:global', {
                    message: `Product name is required`,
                    type: 'error',
                })
                return
            }
            try {
                this.isLoading = true
                const { data } = this.$api.post(url, { 'productFamily': this.form.productName })
                if (Array.isArray(data.data) && !data.data.length) {
                    this.$root.$emit('notification:global', {
                        message: `This product family has no recommended configuration`,
                        type: 'error',
                    })
                    return
                }

                const newConfiguration = {}
                for (let key in data.data) {
                    newConfiguration[key] = data.data[key].map((item) => {
                        return {
                            option: item.attributeOption,
                            alternative: item.attributeOption,
                        }
                    })
                }
                this.configuration = newConfiguration
            } catch (err) {
                this.$root.$emit('notification:global', {
                    message: `Product name is required`,
                    type: 'error',
                    errors: err,
                })
            } finally {
                this.isLoading = false
            }
        },
        checkForChanges () {
            if (this.skipCheckForChanges) {
                return false
            }

            if (this.form.productName !== this.initialValues.productName) {
                return true
            }

            if (this.form.categoryId !== this.initialValues.categoryId) {
                return true
            }

            if (this.initialValues.productConfigurationTemplateId && this.supplierProductImportedDetails && this.initialValues.productConfigurationTemplateId !== this.supplierProductImportedDetails.productConfigurationTemplateId) {
                return true
            }

            if (this.configuration && Object.keys(this.configuration).length && JSON.stringify(this.initialValues.attributeConfiguration) !== JSON.stringify(this.configuration)) {
                return true
            }
            return false
        },
    },
    mounted () {
        this.isLoading = true
        this.isYesNoDialog = this.isNew
        const p1 = this.$store.dispatch('productContent/getProductContentCategories')
        const p2 = this.$api.post(CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.$set(this, 'attributesList', data.data.items)
        })

        let p3 = Promise.resolve()
        if (this.isNew) {
            p3 = this.$store.dispatch('suppliers/fetchSupplierLocations', this.supplierId).then((data) => {
                this.facilities = data.filter(e => e.locationType === 'FACILITY')
                this.facilities.forEach((facility, index) => {
                    if (!Array.isArray(facility.daysOfWeek)) {
                        this.$set(this.facilities[index], 'daysOfWeek', [1, 2, 3, 4, 5])
                        this.$set(this.facilities[index], 'productionDays', [])
                        this.$set(this.facilities[index], 'serviceLevels', {})
                    }
                })
            })
        }
        Promise.all([p1, p2, p3]).finally(() => {
            this.isLoading = false
        })
    },
}
</script>

<style lang="scss" scoped>
.add-product-mapping-template {
    &__button {
        margin: 15px 0 0;
    }

    &__combinations {
        font-weight: normal;
        font-size: 16px;
        margin: 23px 0;
    }

    &__list {
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 10px;

            p {
                margin: 0;
            }
        }
    }
}

.grid-attribute {
    height: calc(100vh - 466px);
}

.inline-add {
    display: inline-block;
    vertical-align: middle;
}
</style>
